import { FC } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import StructuredTextParser from 'molecules/StructuredTextParser/StructuredTextParser';

import type { DatoCmsTemplateLegal, DatoCmsTemplateLegalConnection, Maybe } from 'graphqlTypes';

type DetailProps = Maybe<DatoCmsTemplateLegal> & {
  legals: DatoCmsTemplateLegalConnection;
};

const LegalDetail: FC<DetailProps> = ({ body, title, slug, legals }) => (
  <Flex flexDirection="column" gap="40px" md={{ flexDirection: 'row' }}>
    <Flex
      flexDirection="column"
      width="100%"
      lg={slug === 'cookies-policy' ? undefined : { width: '66.67%', flex: '0 0 66.67%' }}
    >
      <Heading as="h4" textStyle="sm" fontWeight="semiBold" textColor="gray-900" marginBottom={32}>
        {title}
      </Heading>
      {body && <StructuredTextParser text={body} textStyle="lg" textColor="gray-600" />}
    </Flex>
    <Container lg={{ flexGrow: 1, flexBasis: 0 }} display={slug?.includes('cookies') ? 'none' : 'block'}>
      <Flex flexDirection="column" lg={{ flex: '0 0 calc(33.33% - 15px)' }}>
        <Heading as="h5" fontSize="textLg" textColor="gray-900" fontWeight="semiBold" marginBottom={16}>
          Legal Documents
        </Heading>
        <Flex
          as="ul"
          flexDirection="column"
          paddingAll={16}
          gap="12px"
          marginBottom={40}
          css="list-style: none"
          display="none"
          lg={{ display: 'flex' }}
        >
          {legals?.edges?.map(node => (
            <Text
              textStyle="md"
              key={node.node.id}
              as="li"
              paddingLeft={12}
              borderLeft="3px solid"
              borderColor={node.node.slug === slug ? 'primary-800' : 'common-transparent'}
              cursor="pointer"
            >
              <Link
                href={`${!node.node.slug?.includes('cookies') ? '/legal/' : '/'}${node.node.slug}`}
                textDecoration="none"
                fontWeight={node.node.slug === slug ? 'medium' : 'regular'}
                textColor={node.node.slug === slug ? 'gray-900' : 'gray-500'}
              >
                {node.node.title}
              </Link>
            </Text>
          ))}
        </Flex>
      </Flex>
    </Container>
  </Flex>
);

export default LegalDetail;
