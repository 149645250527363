import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';

import type { DatoCmsTemplateLegal } from 'graphqlTypes';
import type { FC } from 'react';

type HeroProps = DatoCmsTemplateLegal;

const BlogHero: FC<HeroProps> = ({ title }) => (
  <Flex paddingTop={128} flexDirection="column">
    <Container width="100%" md={{ maxWidth: '1008px', marginX: 'auto' }}>
      <Flex flexDirection="column" gap="24px" textAlign="center" marginBottom={32} lg={{ marginBottom: 40 }}>
        <Heading
          as="h6"
          fontSize="textMd"
          textTransform="uppercase"
          textColor="secondaryTurqoise-400"
          fontWeight="semiBold"
        >
          {title}
        </Heading>
        <Heading as="h1" textStyle="xl" textColor="common-white" fontWeight="semiBold" letterSpacing="neg2">
          {title}
        </Heading>
      </Flex>
    </Container>
  </Flex>
);

export default BlogHero;
