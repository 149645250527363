import { PageProps, graphql } from 'gatsby';
import {
  DatoCmsComponentConversionPanel,
  DatoCmsSeoField,
  DatoCmsTemplateLegal,
  DatoCmsTemplateLegalConnection,
} from 'graphqlTypes';

import Section from 'molecules/Section/Section';

import ConversionPanel from 'components/ConversionPanel/ConversionPanel';
import LegalDetail from 'components/Legal/LegalDetail';
import LegalHero from 'components/Legal/LegalHero';

import Layout from 'layouts/Layout';

import type { FC } from 'react';

interface LegalDataType {
  legalData: DatoCmsTemplateLegal;
  allLegalPage: DatoCmsTemplateLegalConnection;
  conversionData: DatoCmsComponentConversionPanel;
}

const Legal: FC<PageProps<LegalDataType>> = ({
  data: { legalData, allLegalPage, conversionData },
  location,
  pageContext,
}) => {
  console.warn(`${legalData.internalName} is missing seo`); // TODO: Fix pages that have broken SEO items

  return (
    <Layout
      seo={legalData.seo as DatoCmsSeoField}
      slug={location.pathname}
      domain={(pageContext as any)?.domain}
      lang={(pageContext as any)?.language}
    >
      <Section
        id="legal-hero"
        paddingTopDesktop="80"
        paddingTopTablet="80"
        paddingTopMobile="80"
        paddingBottomDesktop="40"
        paddingBottomTablet="40"
        paddingBottomMobile="40"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
        hasSubfix
        nextHasBackground
      >
        <LegalHero {...legalData} />
      </Section>
      <Section
        id={legalData.internalName || legalData.id}
        paddingTopTablet="48"
        paddingTopMobile="48"
        paddingBottomTablet="48"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-25"
      >
        <LegalDetail {...legalData} legals={allLegalPage} />
      </Section>
      <Section
        id="recent-blog-list"
        paddingTopDesktop="96"
        paddingTopTablet="72"
        paddingTopMobile="48"
        paddingBottomDesktop="96"
        paddingBottomTablet="72"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
        hasPrefix
        hasSubfix
        isLastSection
      >
        <ConversionPanel {...conversionData} />
      </Section>
    </Layout>
  );
};

export default Legal;

export const legalQuery = graphql`
  query templateLegalQuery($slug: String!, $domain: String!, $language: String!) {
    legalData: datoCmsTemplateLegal(slug: { eq: $slug }, domain: { eq: $domain }, language: { eq: $language }) {
      ...datoCmsTemplateLegal
    }
    allLegalPage: allDatoCmsTemplateLegal(filter: { domain: { eq: $domain }, language: { eq: $language } }) {
      edges {
        node {
          ...datoCmsTemplateLegalList
        }
      }
    }
    conversionData: datoCmsComponentConversionPanel(originalId: { eq: "111860821" }) {
      ...datoCmsComponentConversionPanel
    }
  }
`;
